import { useState, useEffect } from "react";

import { auth, db } from "../../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const Environments = ({ domainData }) => {
  const [user, setUser] = useState(null);

  const [data, setData] = useState({
    primary: "",
    secondary: "",
    trafficSplit: "",
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userEmail = currentUser.email;

        const querySnapshot = await getDocs(
          query(collection(db, "memberships"), where("email", "==", userEmail))
        );

        querySnapshot.forEach(async (doc) => {
          setData(doc.data());
        });

        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <header className="major">
                <h2>
                  <span id="ctl00_ContentPlaceHolder1_title">Environments</span>
                </h2>
              </header>
              <br />

              <div>
                <ul style={{ listStyleType: "lower-roman" }}>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Primary Environment(hosting):</strong>{" "}
                    {data.primary === null || data.primary === undefined
                      ? "NA"
                      : data.primary}
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Secondary Environment:</strong>{" "}
                    {data.secondary === null || data.secondary === undefined
                      ? "NA"
                      : data.secondary}
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Traffic Split:</strong>{" "}
                    {data.trafficSplit === null ||
                    data.trafficSplit === undefined
                      ? "NA"
                      : data.trafficSplit}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Environments;
