import { BrowserRouter, Route, Routes } from "react-router-dom";

import DomainContext from "./store/domain-context";
import Home from "./pages/Home";
import AboutUs from "./pages/aboutUs"
// import Guidelines from "./pages/hostingGuidelines";
import OnPage from "./pages/seo/onPageSEO";
import SetUp from "./pages/domainSetUp";
import ImageSEO from "./pages/seo/imageSEO";
import LocalSEO from "./pages/seo/localSEO";
import PageSpeed from "./pages/seo/pageSpeedSEO";
import VideoSEO from "./pages/seo/videoSEO";
import Signup from "./pages/auth/signUp";
import Login from "./pages/auth/login";
import ResetPasswordPage from "./pages/auth/forgotPassword";
import Contact from "./pages/contact";
import Pricing from "./pages/pricing";
import StripePricing from "./pages/stripePayment";
import MyAccount from "./pages/myAccount";
import Themes from "./pages/themes";

import { useEffect, useState } from "react";
import { auth } from "./firebase";

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return async () => {
      unsubscribe();
    };
  }, []);

  const [globalDomainData, setGlobalDomainData] = useState([]);

  return (
    <DomainContext.Provider
      value={{
        globalDomainData,
        setGlobalDomainData,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/set-up" element={<SetUp />} />
          <Route path="/themes" element={<Themes />} />
          {/* <Route path="/hosting-guideline" element={<Guidelines />} /> */}

          <Route path="/on-page-seo" element={<OnPage />} />
          <Route path="/image-seo" element={<ImageSEO />} />
          <Route path="/local-seo" element={<LocalSEO />} />
          <Route path="/page-speed-seo" element={<PageSpeed />} />
          <Route path="/video-seo" element={<VideoSEO />} />

          <Route path="/contact" element={<Contact />} />

          <Route
            path="/my-account"
            element={user ? <MyAccount /> : <Login />}
          />

          <Route path="/pricing" element={<Pricing />} />

          <Route path="/stripe-pricing" element={<StripePricing />} />

          <Route path="forgot_password" element={<ResetPasswordPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </BrowserRouter>
    </DomainContext.Provider>
  );
}

export default App;
