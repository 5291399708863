import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DropDown from "../../../components/dropdown";
import InputControl from "../../../components/inputController";
import { auth, db } from "../../../firebase";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;  
const REGION = process.env.REACT_APP_S3_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_KEY;

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const AddTemplate = ({ domainData }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const [gdriveLink, setGdriveLink] = useState(domainData.gdriveLink);

  const [content, setContent] = useState("GB");
  const [value, setValue] = useState(""); // User Github / G drive
  const [reponaame, setRepoName] = useState("");

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = (file) => {
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: "user2/" + file.lastModifiedDate + "_" + file.name,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        setMessage("Successfully Uploaded");
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (content === "GB") {
      const formData = new FormData();
      formData.append("username", value);
      formData.append("site", reponaame);

      const querySnapshotNew = await getDocs(
        query(collection(db, "userdata"), where("email", "==", user.email))
      );

      let subscriptions = [];

      await Promise.all(
        querySnapshotNew.docs.map(async (doc) => {
          const docData = doc.data();
          if (docData.subscriptions) {
            subscriptions = subscriptions.concat(docData.subscriptions);
          }
        })
      );

      const indexOfObject = subscriptions.findIndex(
        (person) => person.url === domainData.url
      );

      fetch(
        "https://repo-provisioning-api-by6dwsklsq-uc.a.run.app/repo_create",
        {
          method: "POST",
          body: formData,
        }
      )
        .then(async (result) => {
          if (!result.ok) {
            throw new Error("API call failed");
          }
          var { repo_url } = await result.json();

          if (indexOfObject !== -1) {
            subscriptions[indexOfObject].github_repository = repo_url;

            const documentRef = doc(
              db,
              "userdata",
              querySnapshotNew.docs[0].id
            );

            await updateDoc(documentRef, {
              email: user.email,
              subscriptions: subscriptions,
            });

            subscriptions = [];
          } else {
            toast.error(
              "Error Sending data. Please try again in a few minutes",
              {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }

          toast.success(`Repository Created!!`, {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          console.error("Error Sending data:", error);
          toast.error("Error Sending data. Please try again in a few minutes", {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (content === "GD") {
      const formData = new FormData();
      formData.append("username", user.displayName.split(" ")[0].toLowerCase());
      formData.append("id", domainData.domain_id);
      formData.append("url", gdriveLink);

      const querySnapshotNew = await getDocs(
        query(collection(db, "userdata"), where("email", "==", user.email))
      );

      let subscriptions = [];

      await Promise.all(
        querySnapshotNew.docs.map(async (doc) => {
          const docData = doc.data();
          if (docData.subscriptions) {
            subscriptions = subscriptions.concat(docData.subscriptions);
          }
        })
      );

      const indexOfObject = subscriptions.findIndex(
        (person) => person.url === domainData.url
      );

      fetch("https://googledrive-api-5wz7dep6ya-uc.a.run.app/gdrive", {
        method: "POST",
        body: formData,
      })
        .then(async (result) => {
          if (!result.ok) {
            throw new Error("API call failed");
          }
          var { bucket } = await result.json();

          if (indexOfObject !== -1) {
            subscriptions[indexOfObject].gdrive = bucket;
            subscriptions[indexOfObject].gdriveLink = gdriveLink;

            const documentRef = doc(
              db,
              "userdata",
              querySnapshotNew.docs[0].id
            );

            await updateDoc(documentRef, {
              email: user.email,
              subscriptions: subscriptions,
            });

            subscriptions = [];
          } else {
            toast.error(
              "Error Sending data. Please try again in a few minutes",
              {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }

          toast.success(`Bucket has been allocated/updated Successfully!`, {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          console.error("Error Sending data:", error);
          toast.error("Error Sending data. Please try again in a few minutes", {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Error Sending data. Please try again in a few minutes", {
        position: "bottom-left",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return;
    }

    // Add more conditions if needed
  };

  // const UserQueries = {
  //   content: content,
  //   host: host,
  //   deployment: checked,
  // };

  // const UserQueriesRef = ref(database, "UserQueries");

  // await push(UserQueriesRef, UserQueries)
  //   .then(() => {
  //     toast.success("Submitted!!!!", {
  //       position: "bottom-center",
  //       autoClose: 1500,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   })
  //   .then(() => uploadFile(selectedFile))
  //   .then(() => {
  //     setSelectedFile(null);
  //     setMessage("");
  //     setProgress(0);
  //     setContent(true);
  //     setChecked(true);
  //     setDestination(true);
  //     setHost("KW");
  //   });
  // };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login");
      }
    });

    return async () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="login_container container">
        <div>
          <h1 className="login_heading">Upload</h1>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-3">
              <DropDown
                label={
                  <>
                    Content Location{" "}
                    <i
                      className="fas fa-info-circle"
                      title="Select from where to upload your content"
                    />
                  </>
                }
                select={
                  <select
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                    required
                  >
                    <option value="GB">Github</option>
                    <option value="GD">Google Drive</option>
                    <option value="OD">One Drive</option>
                    <option value="DB">DropBox</option>
                    <option value="UL">Upload</option>
                  </select>
                }
              />
            </div>

            {content === "UL" ? (
              <div style={{ marginBottom: "2vh" }}>
                <div>File Upload Progress is {progress}%</div>
                <input type="file" onChange={handleFileInput} />
                <div>{message}</div>
              </div>
            ) : (
              <div style={{ marginBottom: "2vh" }}>
                <InputControl
                  type="text"
                  label={content === "GD" ? "Google Drive: " : "Username: "}
                  placeholder={
                    content === "GB"
                      ? "Github Username"
                      : content === "GD"
                      ? "Drive URL"
                      : "Location URL"
                  }
                  onChange={(e) => {
                    if (content === "GB") {
                      setValue(e.target.value);
                    } else if (content === "GD") {
                      setGdriveLink(e.target.value);
                    }
                  }}
                  value={
                    content === "GB"
                      ? value
                      : content === "GD"
                      ? gdriveLink
                      : ""
                  }
                />
              </div>
            )}

            {content === "GB" ? (
              <div style={{ marginBottom: "2vh" }}>
                <InputControl
                  type="text"
                  label="Repository:"
                  placeholder="Repository Name"
                  onChange={(e) => setRepoName(e.target.value)}
                  value={reponaame}
                />
              </div>
            ) : null}

            {content === "GD" && domainData.gdrive ? (
              <div style={{ marginBottom: "2vh"}}>
                <label htmlFor="gdriveInput" className="form-label">
                  Google Drive Bucket:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="gdriveInput"
                  value={domainData.gdrive}
                  readOnly
                />
              </div>
            ) : null}

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary me-2">
                {content === "GD" ? "Confirm/Update" : "Confirm"}
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate("/");
                }}
                className="btn btn-secondary"
              >
                Cancel
              </button>
            </div>

            {loading ? (
              <div class="spinner-border text-primary mt-4" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTemplate;
