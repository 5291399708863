import { useEffect, useState, useContext } from "react";
import DomainContext from "../../store/domain-context";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Overview from "./overview";
import WebsiteConfiguration from "./websiteConfiguration";
import Environments from "./environments";
import AddTemplate from "./addTemplate";
import Prompts from "./prompts";
import { auth, db } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const MyAccount = () => {
  const { globalDomainData, setGlobalDomainData } = useContext(DomainContext);
  const [user, setUser] = useState();
  const [data, setData] = useState([]);
  const [domainData, setDomainData] = useState([]);

  const handleUrlClick = (item) => {
    setDomainData(item);
    setGlobalDomainData(item);
  };

  const handleCancelSubscription = async () => {
    const querySnapshotNew = await getDocs(
      query(collection(db, "userdata"), where("email", "==", user.email))
    );

    let subscriptions = [];

    await Promise.all(
      querySnapshotNew.docs.map(async (doc) => {
        const docData = doc.data();
        if (docData.subscriptions) {
          subscriptions = subscriptions.concat(docData.subscriptions);
        }
      })
    );

    const indexOfObject = subscriptions.findIndex(
      (person) => person.url === domainData.url
    );

    if (indexOfObject !== -1) {
      subscriptions[indexOfObject].subscription = false;
      subscriptions[indexOfObject].subscriptionCancelDate =
        new Date().toISOString();

      const documentRef = doc(db, "userdata", querySnapshotNew.docs[0].id);

      await updateDoc(documentRef, {
        email: user.email,
        subscriptions: subscriptions,
      });

      subscriptions = [];

      toast.success(`Succesfully Cancelled subscription!!`, {
        position: "bottom-left",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setTimeout(() => {
        // Reload the page
        window.location.reload();
      }, 2000);
    } else {
      toast.error(
        "Error Cancelling Subscription. Please try again in a few minutes",
        {
          position: "bottom-left",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userEmail = currentUser.email;

        const querySnapshotNew = await getDocs(
          query(collection(db, "userdata"), where("email", "==", userEmail))
        );

        querySnapshotNew.forEach(async (doc) => {
          const subscriptions = doc.data().subscriptions;
          if (subscriptions && subscriptions.length > 0) {
            // Filter the array to include only objects with subscription: true
            const filteredSubscriptions = subscriptions.filter(
              (sub) => sub.subscription === true
            );

            setData(filteredSubscriptions);
            if (filteredSubscriptions.length > 0) {
              setDomainData(filteredSubscriptions[0]);
              setGlobalDomainData(filteredSubscriptions[0]);
            }
          }
        });
        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header mb-5">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Artificial Intelligence for Your Web design
              </h1>
              <p className="text-white mb-4 animated slideInRight">
                Build web pages that convert. Publish web pages using AI based
                automation.{" "}
              </p>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/hero-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid wow fadeIn" data-wow-delay="0.1s">
        <div className="container">
          <div className="d-grid gap-2 w-100 mx-auto">
            <button
              className="btn btn-primary btn-lg"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasForURLs"
              aria-controls="offcanvasWithBothOptions"
            >
              Select Website : {domainData.url} (Current)
            </button>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvasForURLs"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
            Your Available Websites
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <ul className="list-group">
            {data
              .filter((item) => item.subscription === true)
              .map((item, index) => (
                <li
                  className={`list-group-item ${
                    item === domainData ? "active" : ""
                  } text-black`}
                  key={index}
                  onClick={() => handleUrlClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  {index + 1}. {item.url}
                </li>
              ))}
          </ul>
        </div>
      </div>

      {/* This is for colapsable accordians */}
      <div className="container-fluid wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center py-5">
          {data.length !== 0 ? (
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Overview
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <Overview domainData={domainData} />

                  <button
                    className="btn btn-outline-primary rounded-pill px-3 m-2 mb-5"
                    onClick={handleCancelSubscription}
                  >
                    Cancel Subscription
                  </button>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Website Configuration
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <WebsiteConfiguration domainData={domainData} />
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Environments
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <Environments domainData={domainData} />
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Upload Content
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <AddTemplate domainData={domainData} />
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Prompts
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <Prompts domainData={domainData} />
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginBottom: "2rem" }}>
              <strong>NO WEBSITES FOUND.Please Buy Some Plans.</strong>
            </div>
          )}

          <Link
            to="/pricing"
            className="d-grid gap-2 col-6 mx-auto btn btn-primary"
          >
            Buy More Websites
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyAccount;
