import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";


const WebsiteConfiguration = ({ domainData }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const [data, setData] = useState({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userEmail = currentUser.email;

        const querySnapshot = await getDocs(
          query(collection(db, "memberships"), where("email", "==", userEmail))
        );

        querySnapshot.forEach(async (doc) => {
          setData(doc.data());
        });

        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [isDisabled, setIsDisabled] = useState(true);
  const [domainName, setDomainName] = useState(
    domainData.domain !== null ? domainData.domain : ""
  );

  const handleSubmit = async () => {
    const querySnapshotNew = await getDocs(
      query(collection(db, "userdata"), where("email", "==", user.email))
    );

    let subscriptions = [];

    await Promise.all(
      querySnapshotNew.docs.map(async (doc) => {
        const docData = doc.data();
        if (docData.subscriptions) {
          subscriptions = subscriptions.concat(docData.subscriptions);
        }
      })
    );

    const indexOfObject = subscriptions.findIndex(
      (person) => person.url === domainData.url
    );
    if (indexOfObject !== -1) {
      subscriptions[indexOfObject].domain = domainName;

      const documentRef = doc(db, "userdata", querySnapshotNew.docs[0].id);

      await updateDoc(documentRef, {
        email: user.email,
        subscriptions: subscriptions,
      });

      subscriptions = [];
      toast.success(
        "Document Saved Successfully!"
      );
    }
  };

  return (
    <>
      <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <header className="major">
                <h2>
                  <span id="ctl00_ContentPlaceHolder1_title">
                    Website Configuration
                  </span>
                </h2>
              </header>
              <br />

              <div>
                <ul style={{ listStyleType: "lower-roman" }}>
                  <li
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <strong>Domain: </strong>
                    <input
                      style={{ margin: "0px 10px" }}
                      disabled={isDisabled}
                      placeholder={
                        domainName === null ||
                        domainName === undefined ||
                        domainName === ""
                          ? "No Domains allocated"
                          : domainName
                      }
                      value={domainName}
                      onChange={(e) => {
                        setDomainName(e.target.value);
                      }}
                    />

                    <FaEdit
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsDisabled(!isDisabled);
                      }}
                    />
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Theme:</strong>{" "}
                    {domainData.theme === null || domainData.theme === undefined
                      ? "Theme Not Selected"
                      : domainData.theme}
                    <Link
                      to="/themes"
                      className="btn btn-outline-primary rounded-pill px-3 m-2"
                    >
                      Select Theme
                    </Link>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Analytics:</strong>{" "}
                    {domainData.analyticsid === null ||
                    domainData.analyticsid === undefined
                      ? "Not Available"
                      : domainData.analyticsid}
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Adsense Code:</strong>
                    {domainData.adsensecode === null ||
                    domainData.adsensecode === undefined
                      ? "Not Available"
                      : domainData.adsensecode}
                  </li>
                </ul>
                <div className="d-flex justify-content-center mt-5">
                  <button
                    className="btn btn-primary me-2"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/");
                    }}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteConfiguration;
