import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";

const PricingTable = () => {
  const [user, setUser] = useState(null);
  const [membership, setMembership] = useState(null);
  const navigate = useNavigate();

  const onSelectPlan = (plan) => {
    navigate({
      pathname: "/stripe-pricing",
      search: createSearchParams({
        pricingPlan: plan,
      }).toString(),
    });
  };

  const getCurrentPlan = async (currentUser) => {
    const membershipsCollectionRef = collection(db, "memberships");
    const existingMembershipQuery = query(
      membershipsCollectionRef,
      where("email", "==", currentUser.email)
    );
    const existingMembershipSnapshot = await getDocs(existingMembershipQuery);
    if (!existingMembershipSnapshot.empty) {
      const membershipDoc = existingMembershipSnapshot.docs[0];
      const membershipData = membershipDoc.data();
      setMembership(membershipData);
    }
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const renderPlan = (planName) => {
    if (!user) {
      return (
        <a href="login" style={{ color: "#ffffff" }}>
          Purchase This Plan Now
        </a>
      );
    }

    if (planName === "standard") {
      return (
        <button
          onClick={() => onSelectPlan("standard")}
          style={{
            color: "#ffffff",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          Purchase This Plan Now
        </button>
      );
    } else if (planName === "startup") {
      return (
        <button
          onClick={() => onSelectPlan("startup")}
          style={{
            color: "#ffffff",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          Purchase This Plan Now
        </button>
      );
    } else {
      //premium
      return (
        <button
          onClick={() => onSelectPlan("premium")}
          style={{
            color: "#ffffff",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          Purchase This Plan Now
        </button>
      );
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        await getCurrentPlan(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h4>
          We Have The Best Pre-Order <em>Prices</em> You Can Get
        </h4>
        <img
          src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/heading-line-dec.png"
          alt=""
        />
        <p className="lead">
          Set up your account and share email address. We will reach out to you
          to point domain to hosting. Then you can start publish content.
        </p>
      </div>

      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-4 mb-4">
            <div className="card box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Standard Plan App</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $10 <small className="text-muted">/ mo</small>
                </h1>
                <div className="icon">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/pricing-table-01.png"
                    alt=""
                  />
                </div>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Best for self employeed</li>
                  <li>Custom domain</li>
                  <li>Storage 4 GB</li>
                  <li>Free SSL</li>
                  <li className="non-function">Premium Add-Ons</li>
                  <li className="non-function">Priority customer support</li>
                  <li className="non-function">More Options</li>
                </ul>
                <div className="btn btn-lg btn-block btn-primary">
                  {renderPlan("standard")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Startup Plan</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $19 <small className="text-muted">/ mo</small>
                </h1>
                <div className="icon">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/pricing-table-01.png"
                    alt=""
                  />
                </div>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Best for small business</li>
                  <li>Custom domain</li>
                  <li>Storage 8 GB</li>
                  <li>Free SSL</li>
                  <li>Unlimited pages</li>
                  <li>Premium Add-Ons</li>
                  <li className="non-function">Priority customer support</li>
                  <li className="non-function">More Options</li>
                </ul>
                <div className="btn btn-lg btn-block btn-primary">
                  {renderPlan("startup")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Premium Plan App</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $39 <small className="text-muted">/ mo</small>
                </h1>
                <div className="icon">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/pricing-table-01.png"
                    alt=""
                  />
                </div>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Publish with prompts</li>
                  <li>Custom domain</li>
                  <li>Storage 100 GB</li>
                  <li>Free SSL</li>
                  <li>Unlimited pages</li>
                  <li>Premium Add-Ons</li>
                  <li>Analytics Integration</li>
                  <li>More Options</li>
                </ul>
                <div className="btn btn-lg btn-block btn-primary">
                  {renderPlan("premium")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
