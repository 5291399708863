import React from "react";
import Header from "../../components/header";
import Subscribe from "../../components/subscribe";
import Footer from "../../components/footer";

const AboutUs = () => {
  return (
    <>
      <Header />

      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                <span id="ctl00_topheader">
                  KreateWebsites: A Product Built By Dataknobs.
                </span>
              </h1>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/hero-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.3s">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <header className="major">
              <h1>
                <span id="ctl00_ContentPlaceHolder1_title">
                  DATAKNOBS COMMITMENTS | HOW WE OPERATE
                </span>
              </h1>
              <span className="byline">
                <span id="ctl00_ContentPlaceHolder1_metadesc">
                  DATAKNOBS FOCUS ON DATA CENTRIC AI. USING KNOBS (ABSTRACT
                  LEVERS) WE MAKE DATA USABLE AND USEFUL IN GOVERNED MANNER.
                  USING KNOBS WE CREATE DATA, AUGMENT DATA, ANONYMIZE DATA AND
                  MAKE IT USABLE. TO MAKE DATA USEFUL WE ENABLE EXPERIMENTATION
                  WITH KNOBS. USING KNOBS ONE CAN ALSO PUBLISH/SHARE INSIGHT TO
                  DIFFERENT CHANNELS.
                </span>
              </span>
            </header>

            <div className="container mt-4 px-0">
              <h2>About Dataknobs</h2>
              <h4>Innovate with Responsibility</h4>
              <p>
                Instead of focusing on sophisticated algorithms, we focus on
                building quality dataset(s) that have high predictive power.
                <br></br>
                "We help you power your AI applications with the data you need"
              </p>

              <h4>Generative AI</h4>
              <p>
                We use generative AI in a controlled manner. We have built
                methods to govern/control/validate generative AI output. We are
                making more progress in this area.
              </p>

              <h2>Our Products</h2>
              <p>
                <strong>KREATE</strong> - Use knobs to generate web experience.
                Behind the scene automation + AI + Generative AI is used.
              </p>
              <p>
                <strong>KONTROLS</strong> - Use knobs to define controls for AI
                and generative AI.
              </p>
              <p>
                <strong>AB Experiment</strong> - Using knobs allows teams to
                experiment quicker and cheaper.
              </p>

              <h2>Our Commitments</h2>
              <p>
                At our company, we are committed to advancing the field of
                artificial intelligence and leveraging its potential to create a
                better world. Our commitments are as follows:
              </p>

              <ul>
                <li>
                  <strong>Ethical AI:</strong> We are committed to building
                  ethical AI systems that prioritize human values and rights.
                  Our AI systems will be designed with transparency, fairness,
                  and accountability in mind, and we will work to mitigate any
                  potential biases and risks associated with their use.
                </li>
                <li>
                  <strong>Innovation:</strong> We are committed to driving
                  innovation in the field of AI and developing cutting-edge
                  technologies that can solve real-world problems. We will
                  invest in research and development, collaborate with industry
                  and academic partners, and continuously improve our AI systems
                  to deliver the best possible results.
                </li>
                <li>
                  <strong>Customer Success:</strong> We are committed to the
                  success of our customers and will work closely with them to
                  understand their unique needs and goals. We will provide them
                  with the best possible solutions and support to help them
                  achieve their objectives.
                </li>
                <li>
                  <strong>Privacy and Security:</strong> We are committed to
                  protecting the privacy and security of our customers' data and
                  ensuring that our AI systems comply with all relevant
                  regulations and standards. We will implement robust security
                  measures and data protection protocols to safeguard our
                  customers' data.
                </li>
                <li>
                  <strong>Diversity and Inclusion:</strong> We are committed to
                  promoting diversity and inclusion in the field of AI and
                  building a team that reflects the diversity of our global
                  community. We will create an inclusive workplace culture that
                  fosters creativity, collaboration, and innovation.
                </li>
              </ul>

              <p>
                By upholding these commitments, we aim to be a trusted partner
                for our customers, a leading innovator in the field of AI, and a
                responsible member of the global community.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Subscribe />

      <Footer />
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default AboutUs;
