import React from "react";
import "./style.css";

const DropDown = (props) => {
  return (
    <div className="input_container">
      {props.label && <label>{props.label}</label>}
      <br />
      {props.select}
    </div>
  );
};

export default DropDown;
