import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark text-white-50 footer pt-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <a href="index.html" className="d-inline-block mb-3">
                <h1 className="text-white">
                  KREATE Websites<span className="text-primary">.</span> &
                  chatbots
                </h1>
              </a>
              <p className="mb-0">
                Build Data Product - Use sae data in multiple channels e.g.
                Website, Apps, Chatbot etc
              </p>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
              <h5 className="text-white mb-4">Get In Touch</h5>
              <p>
                <i className="fa fa-map-marker-alt me-3"></i>Washington, USA
              </p>
              <p>
                <i className="fa fa-phone-alt me-3"></i>+1 4253411222
              </p>
              <p>
                <i className="fa fa-envelope me-3"></i>info@kreatewebsites.com
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/kreatewebsites"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://instagram.com/kreatewebsites?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.linkedin.com/company/kreatewebsites/"
                  target="_black"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
              <h5 className="text-white mb-4">Popular Link</h5>
              <a
                className="btn btn-link"
                href="https://www.dataknobs.com/privacypolicy.html"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
              <h5 className="text-white mb-4">Our Product</h5>
              <a className="btn btn-link" href="https://www.kreatewebsites.com">
                Webdesign and website generation
              </a>
              <a
                className="btn btn-link"
                href="https://www.kreatewebsites.com/#features"
              >
                Features
              </a>
              <a
                className="btn btn-link"
                href="https://www.kreatewebsites.com/#pricing"
              >
                Pricing
              </a>
            </div>
          </div>
        </div>

        <div className="container wow fadeIn" data-wow-delay="0.1s">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <a
                  className="border-bottom"
                  href="https://www.kreatewebsites.com/"
                >
                  KREATE Websites
                </a>
                , All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <a href="/">Home</a>
                  <a href="https://setup.kreatewebsites.com/">Set up steps</a>
                  <a href="page-speed-seo">Page Speed</a>
                  <a href="set-up">Domain name</a>
                  <a href="on-page-seo">SEO</a>
                  <a href="/">FAQs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
