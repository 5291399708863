import { useState, useEffect } from "react";

import { auth, db } from "../../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const Overview = ({ domainData }) => {
  const [user, setUser] = useState(null);

  const [data, setData] = useState({});

  const downloadUserDataAsJson = () => {
    const jsonStr = JSON.stringify(domainData, null, 2); // Convert the data to a formatted JSON string
    const blob = new Blob([jsonStr], { type: "application/json" }); // Create a Blob containing the JSON data
    const url = URL.createObjectURL(blob); // Create a URL for the Blob

    const a = document.createElement("a");
    a.href = url;
    a.download = "userData.json"; // Set the filename for the downloaded file
    a.click();

    URL.revokeObjectURL(url); // Release the URL resource
  };

  const DownloadUserDataButton = () => {
    return <a onClick={downloadUserDataAsJson}>Download File</a>;
  };

  function convertUTCToIST(utcTimestamp) {
    const utcDate = new Date(utcTimestamp);
    const ISTOffset = 330; // Offset for IST in minutes (IST is UTC+5:30)
    const istTimestamp = new Date(utcDate.getTime() + ISTOffset * 60 * 1000);

    // Extract the date part from the IST timestamp
    const istDate = istTimestamp.toLocaleDateString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    return istDate;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (!domainData) {
    return <h1>Loading.....</h1>;
  }

  return (
    <>
      <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <header className="major">
                <h2>
                  <span id="ctl00_ContentPlaceHolder1_title">
                    Account Overview
                  </span>
                </h2>
              </header>
              <br />

              <div>
                <ul style={{ listStyleType: "lower-roman" }}>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Membership Plan:</strong>{" "}
                    {domainData.plan === null || domainData.plan === ""
                      ? "You haven't bought any membership plan"
                      : domainData.plan}
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Start Period:</strong>{" "}
                    {convertUTCToIST(domainData.start_period) === null ||
                    domainData.start_period === ""
                      ? "-"
                      : convertUTCToIST(domainData.start_period)}
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>End Period:</strong>{" "}
                    {convertUTCToIST(domainData.end_period) === null ||
                    domainData.end_period === ""
                      ? "-"
                      : convertUTCToIST(domainData.end_period)}
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Hosting URL:</strong>
                    <a
                      href={`${
                        domainData.url === null || domainData.url === ""
                          ? "#"
                          : "http://" + domainData.url
                      }`}
                      target="_blank" // Add target="_blank" to open links in a new tab
                      rel="noopener noreferrer" // Add rel attribute for security
                    >
                      {" "}
                      {domainData.url === null || domainData.url === ""
                        ? "-"
                        : domainData.url}
                    </a>
                  </li>

                  <li style={{ marginBottom: "10px" }}>
                    <strong>Github Repository:</strong>
                    <a
                      href={`${
                        domainData.github_repository === null ||
                        domainData.github_repository === ""
                          ? "#"
                          : "http://" + domainData.github_repository
                      }`}
                      target="_blank" // Add target="_blank" to open links in a new tab
                      rel="noopener noreferrer" // Add rel attribute for security
                    >
                      {" "}
                      {domainData.github_repository === null ||
                      domainData.github_repository === ""
                        ? "-"
                        : domainData.github_repository}
                    </a>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Google Drive:</strong>
                    <a
                      href={`${
                        domainData.gdriveLink === null ||
                        domainData.gdriveLink === ""
                          ? "#"
                          : "http://" + domainData.gdriveLink
                      }`}
                      target="_blank" // Add target="_blank" to open links in a new tab
                      rel="noopener noreferrer" // Add rel attribute for security
                    >
                      {" "}
                      {domainData.gdriveLink === null ||
                      domainData.gdriveLink === ""
                        ? "-"
                        : domainData.gdriveLink}
                    </a>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Google Drive Bucket:</strong>
                    <a
                      href={`${
                        domainData.gdrive === null ||
                        domainData.gdrive === ""
                          ? "#"
                          : "http://" + domainData.gdriveLink
                      }`}
                      target="_blank" // Add target="_blank" to open links in a new tab
                      rel="noopener noreferrer" // Add rel attribute for security
                    >
                      {" "}
                      {domainData.gdrive === null ||
                      domainData.gdrive === ""
                        ? "-"
                        : domainData.gdrive}
                    </a>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <strong>Download Data: </strong>
                    <DownloadUserDataButton />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
