import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";

const Header = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        navigate("/");
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => unsubscribe();
  }, []);
  return (
    <>
      <div className="container-fluid sticky-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark p-0">
            <Link to="/" className="navbar-brand">
              <h1 className="text-white">
                Kreate<span className="text-dark">.</span>Websites
              </h1>
            </Link>
            <button
              type="button"
              className="navbar-toggler ms-auto me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto align-items-center">
                <NavLink to="/" className="nav-item nav-link">
                  Home
                </NavLink>
                <NavLink to="/about-us" className="nav-item nav-link">
                  About Us
                </NavLink>
                <NavLink to="/set-up" className="nav-item nav-link">
                  Domain Set up
                </NavLink>
                <NavLink to="/themes" className="nav-item nav-link">
                  Themes
                </NavLink>
                <div className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    SEO
                  </div>
                  <div className="dropdown-menu bg-light mt-2">
                    <NavLink to="/on-page-seo" className="dropdown-item">
                      On Page SEO
                    </NavLink>
                    <NavLink to="/image-seo" className="dropdown-item">
                      Image SEO
                    </NavLink>
                    <NavLink to="/local-seo" className="dropdown-item">
                      Local SEO
                    </NavLink>
                    <NavLink to="/page-speed-seo" className="dropdown-item">
                      Page Speed SEO
                    </NavLink>
                    <NavLink to="/video-seo" className="dropdown-item">
                      Video SEO
                    </NavLink>
                  </div>
                </div>
                <NavLink to="/contact" className="nav-item nav-link">
                  Contact
                </NavLink>
                <div>
                  {user ? (
                    <div className="d-flex w-100 align-items-center justify-content-between ">
                      <NavLink className="nav-item nav-link" to="/my-account">
                        My Account
                      </NavLink>

                      <button
                        className="btn btn-primary btn-oval"
                        onClick={handleSignOut}
                      >
                        Log Out <i className="fa fa-sign-out-alt"></i>
                      </button>
                    </div>
                  ) : (
                    <Link className="btn btn-primary btn-oval" to="/login">
                      <i className="fa fa-sign-in-alt"></i> Sign In Now
                    </Link>
                  )}
                </div>
              </div>

              <button
                type="button"
                className="btn text-white p-0 px-4 d-none d-lg-block"
                data-bs-toggle="modal"
                data-bs-target="#searchModal"
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
