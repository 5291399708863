import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/checkoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useSearchParams } from "react-router-dom";

const pricingPlans = {
  standard: `${process.env.REACT_APP_STANDARD_PRICE}`,
  startup: `${process.env.REACT_APP_STARTUP_PRICE}`,
  premium: `${process.env.REACT_APP_PREMIUM_PRICE}`,
};

function StripePayment() {
  // const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [searchParams] = useSearchParams();
  let pricingPlan = searchParams.get("pricingPlan");

  let amount;
  if (pricingPlan === "standard") {
    amount = "$10/month";
  } else if (pricingPlan === "startup") {
    amount = "$19/month";
  } else {
    amount = "$39/month";
  }

  // const [pricingPlan, setPricingPlan] = useState(
  //   searchParams.get("pricingPlan")
  // );

  // useEffect(() => {
  //   fetch(
  //     "https://us-central1-kreate-stripe-api.cloudfunctions.net/api/stripe/config"
  //   ).then(async (r) => {
  //     const { publishableKey } = await r.json();
  //     setStripePromise(loadStripe(publishableKey));
  //   });
  // }, []);

  useEffect(() => {
    fetch(
      "https://us-central1-kreate-stripe-api.cloudfunctions.net/api/stripe/create-payment-intent",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: pricingPlans[pricingPlan],
          secretKey: `${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        }),
      }
    )
      .then(async (result) => {
        if (!result.ok) {
          throw new Error("API call failed");
        }
        var { clientSecret } = await result.json();
        setClientSecret(clientSecret);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <h4>Selected price plan : {pricingPlan}</h4>
        <h4>Amount : {amount}</h4>
        {clientSecret && (
          <Elements
            stripe={loadStripe(
              `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
            )}
            options={{ clientSecret }}
          >
            <CheckoutForm pricingPlan={pricingPlan} />
          </Elements>
        )}
      </div>
    </>
  );
}

export default StripePayment;
