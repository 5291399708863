import {
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  rem,
  Center,
  Paper,
  createStyles,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
} from "@tabler/icons-react";
import { IconAt, IconPhone, IconAlarmFilled } from "@tabler/icons-react";
import { push, ref } from "firebase/database";
import { database } from "../../firebase";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: "border-box",
    background: "#1363c6",
    borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.xl} * 2.5)`,

    [theme.fn.smallerThan("sm")]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    fontFamily: "RINGS, sans-serif",
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    background:
      "linear-gradient(105deg, rgba(91,104,235,1) 0%, rgba(40,225,253,1) 100%)",
  },
}));

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

const Contact = () => {
  const { classes } = useStyles();

  const icons = social.map((Icon, index) => (
    <ActionIcon
      key={index}
      size={28}
      className={classes.social}
      variant="transparent"
    >
      <Icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));
  const handleFormSubmit = (event) => {
    event.preventDefault();

    const contactInfo = {
      firstName: event.target.fullName.value,
      phoneNumber: event.target.phoneNumber.value,
      emailAddress: event.target.emailAddress.value,
      companyName: event.target.companyName.value,
      comments: event.target.comments.value,
    };

    const contactInfoRef = ref(database, "ContactInfo");

    push(contactInfoRef, contactInfo)
      .then(() => {
        console.log("Data saved successfully");
        console.log(contactInfo);
        event.target.reset();
      })
      .catch((error) => {
        console.log("Error saving data:", error);
      });
  };

  return (
    <Center
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Paper
        // style={{
        //   background:
        //     "linear-gradient(105deg, rgba(91,104,235,1) 0%, rgba(40,225,253,1) 100%)",
        // }}
        padding="lg"
        radius="md"
      >
        <form onSubmit={handleFormSubmit}>
          <div className={classes.wrapper}>
            <SimpleGrid
              cols={2}
              spacing={50}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            >
              <div>
                <Title className={classes.title}>Contact us</Title>
                <Text className={classes.description} mt="sm" mb={30}>
                  Leave your email and we will get back to you within 24 hours
                </Text>{" "}
                <Group style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <ActionIcon
                      size={28}
                      className={classes.social}
                      variant="transparent"
                    >
                      <IconAt size="1.4rem" stroke={1.5} />
                    </ActionIcon>
                  </div>
                  <div>
                    <Text className={classes.description} fz="sm">
                      Email
                    </Text>
                    <Text
                      className={classes.title}
                      style={{ marginTop: "5px" }}
                      fz="md"
                    >
                      company@dataknobs.com
                    </Text>
                  </div>
                </Group>
                <Group
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <ActionIcon
                      size={28}
                      className={classes.social}
                      variant="transparent"
                    >
                      <IconPhone size="1.4rem" stroke={1.5} />
                    </ActionIcon>
                  </div>
                  <div>
                    <Text className={classes.description} fz="sm">
                      Phone
                    </Text>
                    <Text
                      className={classes.title}
                      style={{ marginTop: "5px" }}
                      fz="md"
                    >
                      +1 (425) 341-1222
                    </Text>
                  </div>
                </Group>
                <Group
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <ActionIcon
                      size={28}
                      className={classes.social}
                      variant="transparent"
                    >
                      <IconAlarmFilled size="1.4rem" stroke={1.5} />
                    </ActionIcon>
                  </div>
                  <div>
                    <Text className={classes.description} fz="sm">
                      Working Hours
                    </Text>
                    <Text
                      className={classes.title}
                      style={{ marginTop: "5px" }}
                      fz="md"
                    >
                      9am - 7 pm (Pacific Time - Los Angeles)
                    </Text>
                  </div>
                </Group>
                <Group mt="xl">{icons}</Group>
              </div>
              <div className={classes.form}>
                <TextInput
                  id="fullName"
                  label="Full Name"
                  placeholder="John Doe"
                  required
                  radius="md"
                  style={{ marginBottom: "10px" }}
                />
                <TextInput
                  id="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  placeholder=""
                  required
                  radius="md"
                  style={{ marginBottom: "10px" }}
                />
                <TextInput
                  id="emailAddress"
                  label="Email Address"
                  placeholder="johndoe@email.com"
                  type="email"
                  required
                  radius="md"
                  style={{ marginBottom: "10px" }}
                />
                <TextInput
                  id="companyName"
                  label="Company Name"
                  placeholder="DataKnobs"
                  required
                  radius="md"
                  style={{ marginBottom: "10px" }}
                />
                <Textarea
                  placeholder="DataKnobs is the best"
                  minRows={4}
                  mt="md"
                  classNames={{
                    input: classes.input,
                    label: classes.inputLabel,
                  }}
                  id="comments"
                  label="Comments"
                  required
                  radius="md"
                  style={{ marginBottom: "10px" }}
                />

                <Group position="right" mt="md">
                  <Button type="submit" className={classes.control}>
                    Send message
                  </Button>
                </Group>
              </div>
            </SimpleGrid>
          </div>{" "}
        </form>
      </Paper>
    </Center>
  );
};

export default Contact;
