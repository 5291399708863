import React, { useState, useEffect, useContext } from "react";
import DomainContext from "../../store/domain-context";
import Header from "../../components/header";
import Subscribe from "../../components/subscribe";
import Footer from "../../components/footer";
import ThemeItem from "./ThemeItem";
import classes from "./index.module.css";
import { toast } from "react-toastify";

import { auth, db } from "../../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";

const Themes = () => {
  const { globalDomainData, setGlobalDomainData } = useContext(DomainContext);

  //1.code for fetching the themes from the firestore database and also to check if the current user is logged in or not
  const [themes, setthemes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();
  //this useEffect is for fetching the themes from the firestore database
  useEffect(() => {
    const fetchthemes = async () => {
      const querySnapshot = await getDocs(collection(db, "themes"));

      const loadedthemes = [];

      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          // Check if the document exists before accessing its data
          loadedthemes.push({
            id: doc.id,
            name: doc.data().name,
            images: doc.data().images,
            livelink: doc.data().livelink,
          });
        }
      });

      loadedthemes.sort((a, b) => {
        // Compare the "name" property of each object
        const numericA = parseInt(a.name.slice(5));
        const numericB = parseInt(b.name.slice(5));

        return numericA - numericB;
      });

      setthemes(loadedthemes);
      setIsLoading(false);
    };

    fetchthemes().catch((error) => {
      setIsLoading(false);
      setHttpError(`${error.message}... Error Loading Themes`);
    });
  }, []);
  //1.TILL HERE

  //2.Code to update the selectedTheme state
  const [selectedTheme, setSelectedTheme] = useState("");

  const handleThemeSelect = (themeName) => {
    setSelectedTheme(themeName);
  };
  //2.TILL HERE

  //3.Code for Checking that if current logged-in user exists in userdata in db if true then setting ismember to true
  const [user, setUser] = useState(null);
  const [ismember, setIsMember] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const querySnapshot = await getDocs(
          query(
            collection(db, "userdata"),
            where("email", "==", currentUser.email)
          )
        );

        if (!querySnapshot.empty) {
          setUser(currentUser);
          setIsMember(true);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  //3.TILL HERE

  //4.Function to confirm and update the selected theme in Firestore to the current user's currently selected website
  const handleConfirmTheme = async () => {
    const querySnapshotNew = await getDocs(
      query(collection(db, "userdata"), where("email", "==", user.email))
    );

    let subscriptions = [];

    await Promise.all(
      querySnapshotNew.docs.map(async (doc) => {
        const docData = doc.data();
        if (docData.subscriptions) {
          subscriptions = subscriptions.concat(docData.subscriptions);
        }
      })
    );

    const indexOfObject = subscriptions.findIndex(
      (person) => person.url === globalDomainData.url
    );

    if (indexOfObject !== -1) {
      subscriptions[indexOfObject].theme = selectedTheme;

      const documentRef = doc(db, "userdata", querySnapshotNew.docs[0].id);

      await updateDoc(documentRef, {
        email: user.email,
        subscriptions: subscriptions,
      });

      subscriptions = [];
      toast.success(
        "Theme Updated Successfully! You can check under Website Configuration tab under My Account Page "
      );
    } else {
      toast.error(
        "First please select the website from My Account page for which theme is to be selected"
      );
    }
  };
  //4.TILL HERE

  let loadingText = (
    <div className="d-flex justify-content-center mb-5">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  let errorText = (
    <section className={classes.themesError}>
      <p>{httpError}</p>
    </section>
  );

  const themesList = themes.map((theme) => (
    <ThemeItem
      key={theme.id}
      id={theme.id}
      name={theme.name}
      images={theme.images}
      livelink={theme.livelink}
      member={ismember}
      onThemeSelect={handleThemeSelect}
    />
  ));

  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header mb-5">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Discover Your Perfect Theme:Unveil the Beauty of Your Website!
              </h1>
              <p className="text-white mb-4 animated slideInRight">
                Elevate your online presence with our easy-to-use theme
                selection and transform your website into a captivating
                masterpiece. <br></br>From sleek and modern designs to elegant
                and timeless classics, our collection offers the perfect
                backdrop to showcase your unique vision.
              </p>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/hero-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {isLoading && loadingText}
      {httpError && errorText}
      {!isLoading && !httpError && (
        <div className={`container ${classes.themes}`}>
          <h1 className="text-center m-2">List of Available Themes</h1>
          <ul className="row justify-content-between p-0 mb-0 list-unstyled">
            {themesList}
          </ul>
        </div>
      )}

      {!isLoading && !httpError && (
        <div className={classes.submit}>
          <button
            onClick={handleConfirmTheme}
            className={`${
              selectedTheme.trim() === "" ? classes.incorrect : classes.correct
            } ${user === null && ismember === false ? classes.disabled : ""}`}
            disabled={user === null && ismember === false}
          >
            Confirm Selected Theme
            <div>(Please Select One theme)</div>
          </button>
        </div>
      )}

      <Subscribe />
      <Footer />
    </>
  );
};

export default Themes;
