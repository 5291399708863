import React from "react";
import Footer from "../../components/footer";
import Subscribe from "../../components/subscribe";
import Header from "../../components/header";
import { Link } from "react-router-dom";

const SetUp = () => {
  return (
    <>
      <Header />

      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                <span id="ctl00_topheader">
                  "Mastering Domain Pointing: Steps, Elements, and Minimal
                  Downtime"
                </span>
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  {/* <!--
                      <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                      <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                      <li className="breadcrumb-item text-white active" aria-current="page">404 Error</li>
                      --> */}
                </ol>
              </nav>
            </div>

            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/kreatewebsites/sites/site103/img/hero-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(20, 24, 62, 0.7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn btn-square bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-light p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-light px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <header className="major">
                <h2>
                  <span id="ctl00_ContentPlaceHolder1_title">
                    "Mastering Domain Pointing: Steps, Elements, and Minimal
                    Downtime"
                  </span>
                </h2>

                <span className="byline">
                  {" "}
                  <span id="ctl00_ContentPlaceHolder1_metadesc">
                    The article provides a step-by-step guide on how to point a
                    domain to hosting, including obtaining nameservers or DNS
                    records, updating them in the domain registrar's website,
                    and waiting for DNS propagation to complete. It also
                    highlights the elements to consider and the steps to ensure
                    minimal downtime during the process, such as taking backups,
                    informing visitors, choosing a low-traffic period, verifying
                    website functionality, and monitoring after migration.
                  </span>
                </span>
              </header>
              <br />

              <table
                id="ctl00_ContentPlaceHolder1_RepDetails"
                cellSpacing="0"
                border="0"
              >
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span id="ctl00_ContentPlaceHolder1_RepDetails_ctl01_labeltext">
                      <font color="Black" size="4">
                        <div>
                          <h2>Steps to Point Domain to Hosting:</h2>
                          <ol>
                            <li>
                              Obtain the nameservers or DNS records from your
                              hosting provider.
                            </li>
                            <li>Login to your domain registrar's website.</li>
                            <li>Locate the domain management section.</li>
                            <li>
                              Find the option to update nameservers or DNS
                              records.
                            </li>
                            <li>
                              Enter the nameservers or DNS records provided by
                              your hosting provider.
                            </li>
                            <li>
                              Save the changes and wait for the DNS propagation
                              to complete.
                            </li>
                          </ol>

                          <h2>
                            Elements to Consider when Pointing Domain to
                            Hosting:
                          </h2>
                          <ul>
                            <li>
                              Nameservers or DNS records provided by the hosting
                              provider.
                            </li>
                            <li>
                              Domain registrar's website login credentials.
                            </li>
                            <li>
                              Understanding of the domain management section in
                              the registrar's website.
                            </li>
                            <li>
                              Knowledge of how to update nameservers or DNS
                              records.
                            </li>
                            <li>Patience for DNS propagation to complete.</li>
                          </ul>

                          <h2>Ensuring Minimal Downtime:</h2>
                          <ol>
                            <li>
                              Take a backup of your website files and database.
                            </li>
                            <li>
                              Inform your website visitors about the upcoming
                              migration or downtime.
                            </li>
                            <li>
                              Choose a low-traffic period to perform the domain
                              pointing.
                            </li>
                            <li>
                              Follow the steps mentioned above to point the
                              domain to hosting.
                            </li>
                            <li>
                              Verify the website functionality on the new
                              hosting before making it live.
                            </li>
                            <li>
                              Update any hardcoded links or configurations that
                              may be affected by the migration.
                            </li>
                            <li>
                              Monitor the website closely after the migration to
                              ensure everything is working correctly.
                            </li>
                          </ol>
                        </div>
                      </font>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Subscribe />

      <Footer />
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default SetUp;
